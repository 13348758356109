.slideshow-container {
  position: relative;
  height: 75vh;
  width: auto;
  overflow: hidden;
  border-radius: 8px;
}

.slideshow-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* Change this value as needed */
  opacity: 0;
  transition: opacity 1s ease-in-out;
  border-radius: 8px;
}

/* For screens larger than 768px (i.e. desktop devices) */
@media (min-width: 768px) {
  .slideshow-image {
    position: absolute;
    top: 0;
    width: auto;
    height: 75vh;
    object-fit: contain;
    /* Change this value as needed */
    opacity: 0;
    transition: opacity 1s ease-in-out;
    text-align: center;
    margin: 0 auto;
    left: 0;
    right: 0;
    border-radius: 8px;
  }
}

.slideshow-image.active {
  opacity: 1;
  border-radius: 8px;
}