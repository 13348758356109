.catImg {
        width: 100%;
        height: 160px;
        object-fit: cover;
        border-radius: 5px;
        transition: filter 0.3s ease;
}

@media (min-width: 768px) {
        .catImg {
                height: 400px;
        }
}

.rain-background {
        width: 100%;
        height: auto;
        background-size: cover;
        background-position: center;
}